function PromoFriendsModal() {

    var self = new ModalModule();

    self.bodyTemplate('promoFriendsModalTemplate');
    self.headerTemplate('headerPromoFriendsModalTemplate');
    self.modalSize('modal-sm');

    self.isForSupplier = ko.observable(false);
    self.isForFoodGuru = ko.observable(false);
    self.isForFan = ko.observable(false);
    self.isForCatering = ko.observable(false);
    self.catering = null;

    self.reset = function () {
        self.isForSupplier(false);
        self.isForFoodGuru(false);
        self.isForFan(false);
        self.isForCatering(false);
    };

    self.show = function () {
        if (self.isForSupplier()) {
            self.headerLabel('<i class="fa fa-gift"></i> Invita i tuoi Fornitori a iscriversi:<br/>25&euro; per Te con la Promo FRIENDS!');
        } else if (self.isForFoodGuru()) {
            self.headerLabel('<i class="fa fa-gift"></i> Invita i tuoi Amici a seguirti:<br/>i privilegi di Food Guru sono a un clic da Te!')
        } else if (self.isForFan()) {
            self.headerLabel('<i class="fa fa-gift"></i> Invita tutti i Clienti a seguirti:<br/> avrai più Visibilità e più Successo!')
        } else if (self.isForCatering()) {
            self.headerLabel('<i class="fa fa-gift"></i> Invita l\'Attività che ami a iscriversi:<br/>25&euro; per Te con la Promo FRIENDS!');
        }

        self.modalVisible(true);
    };

    self.showPromoFriendsCatering = function () {
        self.reset();
        self.isForCatering(true);
        self.show();
    };
    self.showPromoFriendsFoodGuru = function () {
        self.reset();
        self.isForFoodGuru(true);
        self.show();
    };
    self.showPromoFriendsSupplier = function () {
        self.reset();
        self.isForSupplier(true);
        self.show();
    };
    self.showPromoFriendsFan = function () {
        self.reset();
        self.isForFan(true);
        self.show();
    };

    self.inviteSuppliers = function () {
        if (self.catering && location.href !== '/catering/' + ko.unwrap(self.catering.id) + '/admin/supplier-invite') {
            location.href = '/catering/' + ko.unwrap(self.catering.id) + '/admin/supplier-invite';
        }
        self.close()
    };

    self.cateringInviteUsers = function () {
        if (self.catering && location.href !== '/catering/' + ko.unwrap(self.catering.id) + '/admin/supplier-invite') {
            location.href = '/catering/' + ko.unwrap(self.catering.id) + '/admin/invite';
        }
        self.close()

    };

    self.userInviteUsers = function () {
        location.href = '/user/favorite/users#invite';
    };

    self.userInviteCaterings = function () {
        location.href = '/user/favorite/caterings#locali';
    };

    return self;
}
function SearchModal() {
    var SEARCH_DEFAULT = 'offers'
    // var SEARCH_DEFAULT = 'caterings'

    var self = new ModalModule()
    self.headerLabel('<i class="fa fa-heart-o text-contrast"></i> Cerca')
    self.bodyTemplate('searchModalTemplate')
    self.headerTemplate('headerSearchModalTemplate')
    self.modalSize('modal-md')

    self.cateringsSearchVM = new SearchCateringsModule()
    self.searchBox = new SearchGeoBoxModule()
    self.searchBox.target((typeof TROVACIGUSTO !== 'undefined' && TROVACIGUSTO.target) ? TROVACIGUSTO.target : SEARCH_DEFAULT)

    self.search = function (query) {
        if (query) {
            self.cateringsSearchVM.query(query);
            self.cateringsSearchVM.search();
        }
        self.init();
    };

    return self;
}
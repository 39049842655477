function CateringProcessorModule(offerProcessor) {
    var self = this;
    var globalVariables = {};
    if (typeof TROVACIGUSTO !== 'undefined')
        globalVariables = TROVACIGUSTO;
    self.favoriteCateringsIds = globalVariables.favoriteCateringsIds ? globalVariables.favoriteCateringsIds : [];
    self.favoriteCatering = globalVariables.favoriteCatering ? globalVariables.favoriteCatering : false;
    self.favoriteQuotesIds = globalVariables.favoriteQuotesIds ? globalVariables.favoriteQuotesIds : [];
    self.offerProcessor = offerProcessor ? offerProcessor : null;
    self.quotesVM = new QuoteModule();

    self.process = function (data, target) {
        if (Array.isArray(data)) {
            if (!target) {
                target = [];
            }

            ko.utils.arrayForEach(data, function (item) {
                var processed = self.addInformation(item);
                if (processed)
                    target.push(processed)
            });
            return target;
        }
        return self.addInformation(data);

    };

    self.addInformation = function (item) {

      item.display = ko.observable('compact')
      item.observableFansCatering = ko.observable(item.fans)

      item.quote = ko.observable(self.favoriteQuotesIds.indexOf(item.id) >= 0)
      item.isFavorite = ko.observable(self.favoriteCateringsIds.indexOf(item.id) >= 0 || self.favoriteCatering)

      item.menuOrList = ko.computed(function () {
        return item.isSupplier || item.isShop ? 'Listino' : 'Menù'
      })

      item.isOrderableOnline = ko.observable(item.serviceTakeAwayBooking || item.serviceDeliveryBooking || item.servicePreparedBooking || item.serviceSentBooking || item.serviceTableBooking)
      item.isOnlyReservableOnline = ko.observable((item.serviceTableReserving || item.servicePreparedReserving) && !item.isOrderableOnline())
      item.isBookableOnline = ko.observable(!item.servicesDisableBooking && (item.serviceTableReserving || item.servicePreparedReserving || item.isOrderableOnline()))

      if (item.bundle.activeOffers) {
        if (!self.offerProcessor)
          self.offerProcessor = new OfferProcessorModule()
        item.activeOffers = self.offerProcessor.process(item.bundle.activeOffers)
      }
      if (item.blacklist)
        item.blacklist = ko.observable(item.blacklist)

      item.active = function () {
            return item.status === 'active';
        }

        item.infoButton = function () {
            item.display('full');
            var anchor = "#info-" + item.id;
            var x = $(anchor).offset();
            $(document.body).animate({
                'scrollTop': $(anchor).offset().top - 50
            }, 500);
            window.location.hash = "#main";
            window.location.hash = anchor;
        };

        item.shareButton = function () {
            item.display('full');
            var anchor = "#share-" + item.id;
            var x = $(anchor).offset();
            $(document.body).animate({
                'scrollTop': $(anchor).offset().top - 50
            }, 500);
            window.location.hash = "#main";
            window.location.hash = anchor;
        };

        item.bookingButton = function () {
            item.display('full');
            var anchor = "#booking-" + item.id;
            $(document.body).animate({
                'scrollTop': $(anchor).offset().top - 50
            }, 500);
            window.location.hash = "#main";
            window.location.hash = anchor;
        };

        item.getStaticMap = function (height, width, zoom) {
            if (!height)
                height = 200;
            if (!width)
                width = 300;
            if (!zoom)
                zoom = 14;

            //@TODO set dynamic api key
            return 'https://maps.google.com/maps/api/staticmap?center=' + item.lat + ',' + item.lon + '&size=' + width + 'x' + height + '&markers=label:%7C' + item.lat + ',' + item.lon + '&zoom=' + zoom + '&key=AIzaSyBuY29WgxYvYOqqZblu1uYoMluyVwdP5NY'
        };

        item.addFavoriteCatering = function (hideTestimonial) {
            if (!trovacigusto.userLogged()) {
                trovacigusto.modalLogin.headerLabel('Già Utente?<br>Accedi per diventare Fan');
                trovacigusto.modalLogin.loginRegisterModule.modeInvite(true)
                trovacigusto.modalLogin.loginRegisterModule.catering(item)
                trovacigusto.modalLogin.loginRegisterModule.invitationCode(item.referral_code)
                trovacigusto.modalLogin.loginRegisterModule.senderInviteType('catering')
                trovacigusto.modalLogin.show();
                return;
            }
            Swal.fire({
                title: '<i class="fa fa-heart-o text-contrast" style="margin-top:2px; margin-right:5px;"></i> Vuoi diventare Fan?',
                html: '<p class="text-left">Complimenti, stai per arricchire il tuo food network con questa Attività:</p><br>' +
                    '<ul class="text-left">\n' +
                    '    <li>Troverai sul tuo Diario le sue Proposte sempre aggiornate e potrai ricevere via email o con notifica le migliori novit&agrave;</li>\n' +
                    '    <li>Avrai accesso alle sue esclusive Proposte e Offerte &quot;Solo per Fan&quot;</li>\n' +
                    '</ul>\n',
                showCancelButton: true,
                cancelButtonColor: '#CC1100',
                confirmButtonText: 'Sì, conferma!',
                cancelButtonText: 'No, annulla',
                customClass: {
                    confirmButton: 'btn btn-success mg-r-xs',
                    cancelButton: 'btn btn-gray',
                },
                buttonsStyling: false
            }).then(function (result) {
                    if (result.value) {
                        trovacigusto.flagVM.store('catering', item.id).then(function (response) {
                            if (response.success) {
                                item.isFavorite(true);
                                item.observableFansCatering(parseInt(item.observableFansCatering()) + 1);
                                if (!hideTestimonial) {
                                    trovacigusto.modalTestimonial.catering(item);
                                    trovacigusto.modalTestimonial.load();
                                }
                            } else {
                                if (response.status === 'flag.exist')
                                    item.isFavorite(true);
                            }
                        })
                    }
                }
            );
        };

        item.sendReport = function () {
            var data = {
                type: 'catering',
                id: item.id
            };
            rest('POST', '/api/v2/reports/', data).then(function (response) {
                if (!response.success) {
                    if (response.message === 'report.guest')
                        pNotify('Devi effettuare l\'accesso per poter inviare una segnalazione', 'warning');
                    else
                        pNotify('Problema durante l\'invio della segnalazione. Riprova tra qualche minuto', 'error');
                } else {
                    pNotify('Segnalazione inviata correttamente.');
                }
            });
        };

        item.addQuotes = function () {
            if (!trovacigusto.userLogged()) {
                trovacigusto.modalLogin.headerLabel('Accedi per inviare un messaggio a questa Attività');
                trovacigusto.modalLogin.show();
            } else {

                if (item.quote())
                    location.href = '/user/quotes';

                Swal.fire({
                    title: '<i class="fa fa-envelope"></i> Vuoi comporre subito il messaggio?',
                    showCancelButton: true,
                    cancelButtonColor: '#CC1100',
                    confirmButtonText: 'Sì, grazie!',
                    cancelButtonText: 'No, più tardi',
                    customClass: {
                        confirmButton: 'btn btn-success mg-r-xs',
                        cancelButton: 'btn btn-gray',
                    },
                    buttonsStyling: false
                }).then(function (result) {

                    self.quotesVM.add(item).then(function (response) {
                        if (response.success || response.status === 'flag.exist') {
                            item.quote(true);
                            if (result.value) {
                                window.location.href = "/user/quotes";
                            }
                        }
                    })

                });
            }
        };

        item.delQuotes = function () {
            self.quotesVM.remove(item).then(function (response) {
                if (response.success) {
                    item.quote(false);
                }
            });
        };

        item.showPhoneHome = ko.observable('Telefono');

        item.callCatering = function () {
            if (trovacigusto.userLogged()) {
                if (item.phoneHome === item.showPhoneHome())
                    return location.href = 'tel:' + item.phoneHome;
                item.showPhoneHome(item.phoneHome);
            } else {
                trovacigusto.modalLogin.headerLabel('Accedi per visualizzare il numero di telefono');
                trovacigusto.modalLogin.show();
            }
        };

        item.bookingShow = function () {
            bookingVM.modalBookingCart.setCatering(item);
            bookingVM.modalBookingCart.show();
        };

        item.toggleDetails = function (section) {
            if (item.display() === 'compact') {
                switch (section) {
                    case 'condition':
                        item.display('full');
                        scrollToAnchor('#condition-' + item.id);
                        break;
                    case 'share':
                        item.display('full');
                        scrollToAnchor('#share-' + item.id);
                        break;
                    default:
                        item.display('full');
                        scrollToAnchor('#info-' + item.id);
                }
            } else {
                item.display('compact')
                scrollToAnchor('#id-' + item.id)
            }
        }

        return item;
    }
}


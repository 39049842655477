function HelpModal() {

    var self = new ModalModule();

    self.headerLabel('<i class="fa fa-info-circle"></i> Info');
    self.bodyTemplate('helpModalTemplate');
    self.headerTemplate('headerHelpModalTemplate');

    self.close = function () {
        self.modalVisible(false);
    };

    //TODO da sistemare
    self.reset = function () {
        self.headerLabel('<i class="fa fa-info-circle"></i> Info');
    };

    return self;
}
function NotificationProcessorModule() {
    var self = this;

    /**
     *
     * @param data
     * @param target
     * @returns {*}
     */
    self.process = function (data, target) {
        if (Array.isArray(data)) {
            if (!target) {
                target = [];
            }
            ko.utils.arrayForEach(data, function (item) {

                target.push(self.addInformation(item));
            });
            return target;
        }
        return self.addInformation(data);
    };


    self.addInformation = function (item) {
        item = ko.mapping.fromJS(item);
        item.tooltip = ko.computed(function () {
            return item.read() ? "Segna come da leggere" : "Segna come letta";
        });
        return item;
    }
}

function SystemNotificationModule() {
    var self = this;

    self.unreadNotifications = ko.observable(0);
    self.notifications = ko.observableArray([]);
    self.allNotifications = ko.observableArray(0);
    self.loadedAllNotifications = ko.observable(false);
    self.onlyUnread = ko.observable(false);
    self.notification = ko.observable(null);
    self.pagination = new PaginationModule(null, new NotificationProcessorModule());
    self.loading = ko.observable(false);

    self.processing = ko.computed(function () {
        return self.pagination.loading() || self.loading();
    });

    self.resetFilter = ko.computed(function () {
        if (self.onlyUnread() && self.unreadNotifications() <= 0) {
            self.load(null, 0, null, true);
        }
    });

    self.readNotifications = ko.computed(function () {
        return self.allNotifications() - self.unreadNotifications();
    });

    self.filterText = ko.computed(function () {
        return self.onlyUnread() ? "Tutte" : "Da leggere: " + self.unreadNotifications().toString();
    });

    self.load = function (limit, unread, offset, reset) {
        self.loading(true);
        if (reset)
            self.pagination.reset();

        self.countUnread().then(function (response) {
            var url = '/api/v2/system-notifications?unread=' + ((unread !== undefined) ? unread : "0");
            if (limit) {
                url = url + '&perPage=' + limit;
            }
            self.onlyUnread(unread ? unread : false);

            self.pagination.url(url);
            return self.pagination.load().then(function (response) {
                if (response.success) {
                    if (!unread) {
                        self.allNotifications(response.data.total);
                    }

                    self.loadedAllNotifications(response.data.last_page === response.data.current_page && !unread);
                }
                return response;
            });
        }).then(function (response) {
            self.loading(false);
        }).catch(function (response) {
            self.loading(false);
        });

    };

    self.loadUnread = function () {
        self.load(null, self.onlyUnread() ? 0 : 1, null, true);
    };

    self.countUnread = function () {
        return rest('GET', '/api/v2/system-notifications/count-unread').then(function (response) {
            if (response.data)
                self.unreadNotifications(response.data.count);

            return response;
        });
    };

    self.confirmDelete = function (notification) {
        Swal.fire({
            title: 'Sei sicuro di volerla eliminare?',
            text: "Stai per eliminare definitivamente questa notifica",
            showCancelButton: true,
            cancelButtonColor: '#CC1100',
            confirmButtonText: 'Sì, elimina',
            cancelButtonText: 'No, mantieni',
            customClass:{
                confirmButton: 'btn btn-success mg-r-xs',
                cancelButton: 'btn btn-danger',
            },
            buttonsStyling: false
        }).then(function (result) {
            if (result.value) {
                self.delete(notification);
            }
        });
    };

    self.confirmDeleteAll = function () {
        Swal.fire({
            title: 'Sei sicuro di volerle eliminare?',
            text: "Stai per eliminare definitivamente tutte le notifiche già lette",
            showCancelButton: true,
            cancelButtonColor: '#CC1100',
            confirmButtonText: 'Sì, elimina',
            cancelButtonText: 'No, mantieni',
            customClass:{
                confirmButton: 'btn btn-success mg-r-xs',
                cancelButton: 'btn btn-danger',
            },
            buttonsStyling: false
        }).then(function (result) {
            if (result.value) {
                self.deleteAllRead();
            }
        });
    };

    self.delete = function (notification) {
        return rest('DELETE', '/api/v2/system-notifications/' + notification.id()).then(function (response) {
            if (response.success) {
                ko.utils.arrayForEach(self.pagination.data(), function (item) {
                    if (item.id() === notification.id() && !item.read()) {
                        item.read(true);
                        self.unreadNotifications(self.unreadNotifications() > 0 ? self.unreadNotifications() - 1 : 0);
                    }
                });
                self.pagination.data.remove(notification);
            }
            return response;
        });
    };

    self.deleteAllRead = function (notification) {
        return rest('GET', '/api/v2/system-notifications/delete-all-read/').then(function (response) {
            if (response.success) {
                self.refresh();
            }
            return response;
        });
    };

    self.show = function (notification) {
        return rest('GET', '/api/v2/system-notifications/show/' + notification.id()).then(function (response) {
            if (response.success) {
                ko.utils.arrayForEach(self.pagination.data(), function (item) {
                    if (item.id() === response.data.id && !item.read()) {
                        item.read(true);
                        self.unreadNotifications(self.unreadNotifications() > 0 ? self.unreadNotifications() - 1 : 0);
                    }
                });
                self.notification(response.data);
                return response;
            }
            return response.status;
        });
    };

    self.link = function (notification) {

        if (notification.read()) {
            if (notification.link() !== "#")
                location.replace(notification.link());
            return;
        }

        return rest('GET', '/api/v2/system-notifications/show/' + notification.id()).then(function (response) {
            if (response.success) {
                if (response.data.link !== "#")
                    location.replace(response.data.link);
                else {
                    ko.utils.arrayForEach(self.pagination.data(), function (item) {
                        if (item.id() === response.data.id && !item.read()) {
                            item.read(true);
                            self.unreadNotifications(self.unreadNotifications() > 0 ? self.unreadNotifications() - 1 : 0);
                        }
                    });
                }
                return response;
            }
            return response.status;
        });
    };

    self.markAllRead = function () {
        return rest('GET', '/api/v2/system-notifications/mark-all-read').then(function (response) {
            if (response.success) {
                ko.utils.arrayForEach(self.pagination.data(), function (item) {
                    item.read(true);
                });
                self.unreadNotifications(0);
            }
        });
    };

    self.toogleRead = function (notification) {
        return rest('GET', '/api/v2/system-notifications/set-read/' + notification.id()).then(function (response) {
            if (response.success) {
                ko.utils.arrayForEach(self.pagination.data(), function (item) {
                    if (item.id() === response.data.id) {
                        item.read(response.data.read);
                        var count;
                        if (response.data.read) {
                            count = self.unreadNotifications() > 0 ? self.unreadNotifications() - 1 : 0;
                        } else {
                            count = self.unreadNotifications() + 1;
                        }
                        self.unreadNotifications(count);
                    }
                });
            }
            return response.status;
        });
    };

    self.refresh = function (limit, unread, offset) {
        self.load(limit, unread, offset, true);
    };

    return self;
}
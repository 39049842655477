function OfferProcessorModule (cateringProcessor) {
  var self = this
  var globalVariables = {}
  if (typeof TROVACIGUSTO !== 'undefined')
    globalVariables = TROVACIGUSTO

  self.favoritesOffersIds = globalVariables.favoritesOffersIds ? globalVariables.favoritesOffersIds : []
  self.cateringProcessor = cateringProcessor ? cateringProcessor : null

  /**
   *
   * @param data
   * @param target
   * @returns {*}
   */
  self.process = function (data, target) {
    if (Array.isArray(data)) {
      if (!target) {
        target = []
      }
      ko.utils.arrayForEach(data, function (item) {
        var information = self.addInformation(item)
        if (information)
          target.push(information)
      })
      return target
    }
    return self.addInformation(data)
  }

  self.addInformation = function (item) {
    item.display = open === item.id ? ko.observable('full') : ko.observable('compact')
    item.loading = ko.observable(false)

    if (item.catering) {

      item.tooltipArray = []

      if (item.catering.serviceDelivery && item.serviceDelivery) {
        if (item.catering.serviceDeliveryAverageTime > 0) {
          item.tooltipArray.push('Consegna media in ' + item.catering.serviceDeliveryAverageTime + ' min')
        }
        if (item.catering.serviceDeliveryPriceMin > 0) {
          item.tooltipArray.push('Spesa minima per la consegna ' + item.catering.serviceDeliveryPriceMin + '€')
        }
        if (item.catering.serviceDeliveryFreeThreshold > 0) {
          item.tooltipArray.push('Consegna gratuita per importi superiori a ' + item.catering.serviceDeliveryFreeThreshold + '€')
        }
        if (item.catering.serviceDeliveryPrice > 0) {
          item.tooltipArray.push('Costo della consegna ' + item.catering.serviceDeliveryPrice + '€')
        } else {
          item.tooltipArray.push('Consegna gratuita')
        }

        //@FIXME sistemare visualizzazione raggio
        if (item.catering.range) {
          item.tooltipArray.push('Raggio di consegna ' + item.catering.range.distance + ' Km')
        }
        if (item.catering.serviceDeliveryInfo !== '') {
          item.tooltipArray.push('Info: ' + item.catering.serviceDeliveryInfo)
        }
      }

      if (item.catering.serviceSentHome && item.serviceSentHome) {
        if (item.catering.serviceSentPriceMin > 0) {
          item.tooltipArray.push('Spesa minima per la spedizione ' + item.catering.serviceSentPriceMin + '€')
        }
        if (item.catering.serviceSentFreeThreshold > 0) {
          item.tooltipArray.push('Spedizione gratuita per importi superiori a ' + item.catering.serviceSentFreeThreshold + '€')
        }
        if (item.catering.serviceSentPrice > 0) {
          item.tooltipArray.push('Costo della spedizione ' + item.catering.serviceSentPrice + '€')
        } else {
          item.tooltipArray.push('Spedizione gratuita')
        }
        if (item.catering.serviceSentInfo !== '') {
          item.tooltipArray.push('Info: ' + item.catering.serviceSentInfo)
        }
      }

      item.tooltip = item.tooltipArray.join('\n')

      if (self.cateringProcessor)
        item.catering = self.cateringProcessor.process(item.catering)
    }

    item.isFavoriteOffer = ko.observable(self.favoritesOffersIds.indexOf(item.id) >= 0)

    if (item.categories) {
      item.cuisine = item.categories.filter(function (key) {
          return key.type === 'cuisine'
        }
      )
    }

    item.observableFansOffer = ko.observable(item.fans)

    item.isPromotion = ko.observable(['promotion-discount', 'promotion-dish', 'promotion'].includes(item.type))

    item.toggleFavoriteOffer = function () {
      if (!trovacigusto.userLogged()) {
        trovacigusto.modalLogin.headerLabel('Accedi per mettere Mi piace')
        trovacigusto.modalLogin.show()
        return Promise.resolve()
      }
      if (item.isFavoriteOffer()) {
        return Swal.fire({
          title: 'Vuoi levare il tuo "Mi piace" alla Proposta?',
          text: '',
          showCancelButton: true,
          cancelButtonColor: '#CC1100',
          confirmButtonText: 'Sì, levalo',
          cancelButtonText: 'No, tienilo',
          customClass: {
            confirmButton: 'btn btn-success mg-r-xs',
            cancelButton: 'btn btn-danger',
          },
          buttonsStyling: false
        }).then(function (result) {
          if (result.value) {
            item.loading(true)
            return trovacigusto.flagVM.delete('offer', item.id)
              .then(function (response) {
                if (response.success) {
                  item.isFavoriteOffer(false)
                  item.observableFansOffer(parseInt(item.observableFansOffer()) - 1)
                  pNotify('Ora non Mi Piace più questa Proposta')
                }
                item.loading(false)
              })
              .catch(function (response) {
                item.loading(false)
                return Promise.reject(new Error())
              })
          }
        })
      } else {
        item.isFavoriteOffer(true)
        trovacigusto.modalOfferShareUser.load(item)
        item.loading(true)
        return trovacigusto.flagVM.store('offer', item.id)
          .then(function (response) {
            if (response.success) {
              item.isFavoriteOffer(true)
              item.observableFansOffer(parseInt(item.observableFansOffer()) + 1)
            } else {
              if (response.status !== 'flag.exist')
                item.isFavoriteOffer(false)
            }
            item.loading(false)
          })
          .catch(function (response) {
            item.isFavoriteOffer(false)
            item.loading(false)
            return Promise.reject(new Error())
          })
      }
    }

    item.showFlags = function () {
      trovacigusto.modalOfferFlags.offer(item)
      trovacigusto.modalOfferFlags.load()
    }

    item.bookingButton = function () {
      if (item.catering.isBookableOnline()) {
        item.addOfferToCart(true)
      } else {
        item.conditionButton()
      }
    }

    item.conditionButton = function () {
      item.display('full')
      var anchor = '#condition-' + item.id
      scrollToAnchor(anchor)
    }

    item.infoButton = function () {
      //<a data-toggle="tab" href="#tab-map" aria-expanded="true">
      item.display('full')
      var anchor = '#info-' + item.id

      scrollToAnchor(anchor)
    }

    item.shareButton = function () {
      item.display('full')
      var anchor = '#share-' + item.id
      scrollToAnchor(anchor)
    }

    item.quoteButton = function () {
      item.display('full')
      var anchor = '#quote-' + item.id
      scrollToAnchor(anchor)

    }

    item.toggle = function (section) {
      if (item.display() === 'compact') {
        switch (section) {
          case 'condition':
            item.display('full')
            scrollToAnchor('#condition-' + item.id)
            break
          case 'info':
            item.display('full')
            scrollToAnchor('#info-' + item.id)
            break
          case 'title':
            item.display('full')
            scrollToAnchor('#title-' + item.id)
            break
          default:
            item.display('full')
            scrollToAnchor('#id-' + item.id)
            break
        }
      } else {
        item.display('compact')
        scrollToAnchor('#id-' + item.id)
      }

    }

    item.cleanShareText = function (text, uri) {
      if (!text && !uri)
        return encodeURIComponent(item.bundle.seo)

      return encodeURIComponent(text) + ' ' + (uri ? uri : item.bundle.seo)
    }

    item.getStaticMap = function (height, width, zoom) {
      if (!height)
        height = 200
      if (!width)
        width = 300
      if (!zoom)
        zoom = 14

      //@TODO set dynamic api key
      return 'https://maps.google.com/maps/api/staticmap?center=' + item.lat + ',' + item.lon + '&size=' + width + 'x' + height + '&markers=label:%7C' + item.lat + ',' + item.lon + '&zoom=' + zoom + '&key=AIzaSyBuY29WgxYvYOqqZblu1uYoMluyVwdP5NY'
    }

    item.shareFB = function () {
      if (isGonative())
        gonative.share.sharePage();
      if (isMobile()) {
        window.open('https://www.facebook.com/sharer/sharer.php?app_id=836519186411810&sdk=joey&display=popup&ref=plugin&src=share_button&u=' + item.cleanShareText())
      } else {
        FB.ui({
          method: 'share',
          href: item.bundle.seo,
        }, function (response) {
          if (response) {

          }
        })
      }
    }

    item.shareWhatsapp = function () {
      var text = item.name + (item.menuText ? ': ' + (item.menuText.substr(0, 100)).replace(/<(?:.|\s)*?>/gm, '') + '... ' : ' ') + (item.descriptionShort ? ': ' + (item.descriptionShort.substr(0, 100)).replace(/<(?:.|\s)*?>/gm, '') + '... ' : ' ')
      window.open('https://api.whatsapp.com/send?text=' + item.cleanShareText(text, item.bundle.seo), 'whatsapp-share',
        'left=0,top=0,width=550,height=450,personalbar=0,toolbar=0,scrollbars=0,resizable=0')
    }

    item.shareTwitter = function () {
      window.open('https://twitter.com/share?url=' + encodeURIComponent(item.bundle.seo) + '&text=' + encodeURIComponent('Proposta: ' + item.name), 'twitter-share',
          'left=0,top=0,width=550,height=450,personalbar=0,toolbar=0,scrollbars=0,resizable=0')
    }

    item.shareSMS = function () {
      window.open('sms:?body=' + encodeURIComponent(item.name) + ' ' + encodeURIComponent(item.bundle.seo), 'sms-share',
        'left=0,top=0,width=550,height=450,personalbar=0,toolbar=0,scrollbars=0,resizable=0')
    }

    item.fbSend = function () {
      if (isMobile() || isGonative()) {
        window.open('https://www.facebook.com/sharer/sharer.php?app_id=836519186411810&sdk=joey&display=popup&ref=plugin&src=share_button&u=' + item.cleanShareText())
      } else {

        FB.ui({
          method: 'send',
          link: item.bundle.seo
        })
      }
    }

    item.goToDelivery = function () {
      var anchor = $('.nav-tabs a[href=\\#tab-delivery]')
      if (anchor.length > 0) {
        anchor.tab('show')
        $('html, body').animate({ scrollTop: 0 }, 'slow')
      } else {
        window.open(item.catering.bundle.seo + '#tab-delivery', '_blank')
      }
    }

    item.sendReport = function () {
      var data = {
        type: 'offer',
        id: item.id
      }
      rest('POST', '/api/v2/reports/', data).then(function (response) {
        if (!response.success) {
          if (response.message === 'report.guest')
            pNotify('Devi effettuare l\'accesso per poter inviare una segnalazione', 'warning')
          else
            pNotify('Problema durante l\'invio della segnalazione. Riprova tra qualche minuto', 'error')
        } else {
          pNotify('Segnalazione inviata correttamente.')
        }
      })
    }

    item.clone = function () {
      rest('GET', '/api/v2/offers/' + item.id + '/clone')
        .then(function (response) {
          if (response.success) {
            window.location.href = '/catering/' + response.data.catering.id + '/admin/offers/' + response.data.offer.id
          } else {
            throw new Error(response.status)
          }
        })
        .catch(function (res, err) {
          pNotify('Ci dispiace, non è stato possibiel copiare questa Proposta', 'error')
        })
    }

    item.addOfferToCart = function (openModal) {
      if (item.linked_offer_id && item.linked_offer && item.catering.isFoodGuruGroup) {
        return item.addInternalOfferToCart()
      }

      if (bookingVM && bookingVM.modalBookingCart && bookingVM.modalBookingCart.cartModule) {
        bookingVM.modalBookingCart.cartModule.cateringId(item.cid)
        bookingVM.modalBookingCart.setCatering(item.catering)
        bookingVM.modalBookingCart.cartModule.loadByCatering(true)
          .then(function (response) {
            bookingVM.modalBookingCart.cartModule.addItemById(item.id, 'offer')
              .then(function (response) {
                var message = 'Aggiunto alla Richiesta di Prenotazione | Ordine'
                if (openModal) {
                  bookingVM.modalBookingCart.show()
                  pNotify(message, 'success')
                } else {
                  message += '<br><br><a href=\'#\' class=\'text-primary\'>Vedi &raquo;</a>'
                  pNotify(message, 'success', 5000)
                    .on('click', function (e) {
                      if ([...bookingnotice.refs.elem.querySelectorAll('.pnotify-closer *, .pnotify-sticker *')].indexOf(e.target) !== -1) {
                        return
                      }
                      bookingnotice.close()
                      bookingVM.modalBookingCart.show()
                    })
                }
              })
              .catch(function () {

              })
          })
          .then(function (res) {
            bookingVM.bookingModule.loadDrafts()
          })
          .catch(function (res) {
            pNotify('Errore', 'danger')
          })

      }
    }

    item.addInternalOfferToCart = function (openModal) {

      var url = new URL(item.linked_offer.bundle.seo, getBaseURL())
      url.searchParams.set('ref_offer', item.catering.referral_code)
      location.href = url.href
      /**
       * @trello [10017]
       *  aggiunge l'offer e va alla pagina. va rivista laggiunta al carrello perchè in anteprima sembra che sia pieno il carrello del foodguru
       */
      if (bookingVM && bookingVM.modalBookingCart && bookingVM.modalBookingCart.cartModule) {

        if (!item.linked_offer_id || !item.linked_offer || !item.catering.isFoodGuruGroup) {
          return
        }
        bookingVM.modalBookingCart.cartModule.cateringId(item.linked_offer.cid)
        bookingVM.modalBookingCart.cartModule.loadByCatering(true)
          .then(function (response) {
            bookingVM.modalBookingCart.cartModule.addItemById(item.linked_offer_id, 'offer')
              .then(function (response) {
                var message = 'Aggiunto alla Richiesta di Prenotazione | Ordine<br> verrai reindirizzato alla pagina della Proposta collegata'
                pNotify(message, 'success')
                var url = new URL(item.linked_offer.bundle.seo, getBaseURL())
                url.searchParams.set('cart', bookingVM.modalBookingCart.cartModule.cart().id())
                location.href = url.href
              })
              .catch(function () {

              })
          })
          .catch(function (res) {
            pNotify('Errore', 'danger')
          })

      }
    }

    item.internalLink = ko.computed(function () {
      var url = new URL(item.bundle.seo, getBaseURL())
      if (item.linked_offer_id && item.linked_offer && item.catering) {
        url = new URL(item.linked_offer.bundle.seo, getBaseURL())
        url.searchParams.set('ref_offer', item.catering.referral_code)
      }
      if (item.linked_catering_id && item.linked_catering && item.catering) {
        url = new URL(item.linked_catering.bundle.seo, getBaseURL())
        url.searchParams.set('ref_offer', item.catering.referral_code)
      }
      return url
    })

    item.serviceOrderable = ko.computed(function () {
      var service = []
      if (!item.catering)
        return ''
      if (item.catering.serviceTableBooking && item.serviceTable) {
        service.push('Al Tavolo nel Locale')
      }
      if (item.catering.serviceTakeAwayBooking && item.serviceTakeAway) {
        service.push('Asporto/Acquisto')
      }
      if (item.catering.serviceDeliveryBooking && item.serviceDelivery) {
        service.push('Consegna a Domicilio')
      }
      if (item.catering.servicePreparedBooking && item.servicePreparedHome) {
        service.push('Chef/Catering')
      }
      if (item.catering.serviceSentBooking && item.serviceSentHome) {
        service.push('Spedizione a Domicilio')
      }

      return service.join(', ')
    })

    return item
  }

}
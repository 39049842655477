function OfferFlagsModal() {

    var self = new ModalModule();
    self.headerLabel('');
    self.bodyTemplate('offerFlagsModalTemplate');
    self.headerTemplate('headerOfferFlagsModalTemplate');
    self.modalSize('modal-md');
    self.query = ko.observable('');
    self.offer = ko.observable(null);
    self.fansPagination = new PaginationModule();


    self.toggleFavoriteOffer = function () {
        self.offer().toggleFavoriteOffer().then(function (result) {
            self.fansPagination.load()
        });
    }

    self.load = function () {
        if (self.offer()) {
            self.fansPagination.processor(trovacigusto.flagProcessor);
            self.fansPagination.url('/api/v2/offers/' + self.offer().id + '/fans/?include=user,user.address&perPage=3');
            self.fansPagination.load()
        }
        self.show();

    }

    self.filter = function () {
        if (self.offer()) {
            self.fansPagination.processor(trovacigusto.flagProcessor);
            self.fansPagination.url('/api/v2/offers/' + self.offer().id + '/fans/?include=user,user.address&perPage=3&q=' + self.query());
            self.fansPagination.load()
        }
    }


    return self;
}

function PaginationModule(url, processor) {
    var self = this
    self.indexPage = ko.observable(1)
    self.url = ko.observable(url ? url : '')
    self.resultIdElement = ko.observable('#pagination-result')
    self.currentPage = ko.observable(1)
    self.lastPage = ko.observable(1)
    self.data = ko.observableArray([])
    self.total = ko.observable(0)
    self.perPage = ko.observable(6)
    self.template = ko.observable()
    self.query = ko.observable()
    self.from = ko.observable()
    self.to = ko.observable()
    self.sort = ko.observable() //sort columns
    self.order = ko.observable() //custom order
    self.directionSort = ko.observable('asc')
    self.moreText = ko.observable('Carica altri risultati')
    self.processor = ko.observable(processor ? processor : null)
    self.remain = ko.computed(function () {
        return self.total() - self.to()
    })

    self.empty = ko.computed(function () {
        return self.data.length <= 0
    })

    self.loading = ko.observable(false)

    self.processed = ko.observable(false);

    self.canGoNext = ko.computed(function () {
        return self.currentPage() + 1 <= self.lastPage();
    });

    self.canGoPrevious = ko.computed(function () {
        return self.currentPage() - 1 > 0
    });

    self.goPrevious = function () {
        if (self.canGoPrevious()) {
            self.indexPage(self.currentPage() - 1)
            self.load(false)
        }
    }

    self.goNext = function () {
        if (self.canGoNext()) {
            self.indexPage(self.currentPage() + 1)
            self.load(false)
        }
    }

    self.loadMore = function () {
        if (self.canGoNext()) {
            self.indexPage(self.currentPage() + 1);
            return self.loadData();
        }
    };

    self.setSort = function (sort, direction) {
        if (!sort)
            return
        self.sort(sort)
        self.directionSort(direction ? direction : 'asc')
    }

    self.setOrder = function (order, direction) {
        if (!order)
            return
        self.order(order)
        self.directionSort(direction ? direction : 'asc')
    }

    self.reset = function () {
        self.data([]);
        self.total(0);
        self.currentPage(1);
        self.indexPage(1);
        self.lastPage(1);
    };

    self.loadData = function () {
        if (self.loading())
            return $.Deferred().then()
        self.loading(true)
        self.processed(false)

        var url = new URL(self.url(), getBaseURL())
        url.searchParams.set('page', self.indexPage())
        url.searchParams.set('perPage', self.perPage())

        if (self.query())
            url.searchParams.set('q', self.query())

        if (self.sort()) {
            url.searchParams.set('sort', self.sort() + '.' + self.directionSort())
        }
        if (self.order()) {
            url.searchParams.set('order', self.order())
            url.searchParams.set('dir', self.directionSort())
        }

        return rest('GET', url.href)
            .then(function (response) {
                if (response.success) {
                    var data = response.data.data ? response.data : response
                    self.currentPage(data.current_page)
                    self.lastPage(data.last_page)
                    self.total(data.total)
                    self.from(data.from)
                    self.to(data.to)
                    //@TODO rimuovere for each perchè gia nel preprocessor
                    ko.utils.arrayForEach(data.data, function (item) {
                        if (self.processor()) {
                            var processed = self.processor().process(item)
                            self.data.push(processed)
                        } else
                            self.data.push(item)
                    })
                }
                self.processed(true)
                self.loading(false)

                //@TODO disabilitato temporaneamente per evitare che ogni caricamento asyncrono faccia scrollare la pagina
                //va trovata una soluzione migliore che scrolla solo la prima volta o aggiunger un opzione per disabilitare/abilitare lo scroll singolarmente
                /*
                if (typeof scrollToAnchor === "function")
                     scrollToAnchor(self.resultIdElement())
                 else
                     window.scrollTo(0, self.resultIdElement())
                     */

                return response
            })
            .catch(function (response) {
                self.reset();
                self.loading(false);
                return response;

            });
    };

    self.load = function (forceReload) {
        if (forceReload === undefined)
            forceReload = true;

        if (self.url() === '') {
            return Promise.reject(new Error('missing.url'));
        }
        if (self.data().length > 0) {
            self.data.removeAll();
        }
        if (self.data().length > 0 && forceReload) {
            self.reset();
        }
        if (self.data().length <= 0 || (self.indexPage() !== self.currentPage())) {
            return self.loadData();
        }
        return Promise.resolve();

    };

    self.filter = function () {
        self.reset();
        return self.load();
    };

    self.removeItem = function (item) {
        if (self.data().length > 0) {
            item = self.data.remove(item);
            if (item)
                self.total((self.total() - 1) >= 0 ? self.total() - 1 : 0)
        }
    };

    self.reload = function () {
        self.reset();
        return self.loadData();

    };

    return self;
}
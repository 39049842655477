function UserTestimonialModal () {

  var self = new ModalModule()

  self.catering = ko.observable()
  self.user = ko.observable()
  self.testimonial = ko.observable(null)

  self.headerLabel('<i class="fa fa-heart-o text-contrast"></i> Scrivi la "Dichiarazione" (d\'amore!) e aumenta la tua popolarità!\n')
  self.bodyTemplate('testimonialModalTemplate')
  self.headerTemplate('headerTestimonialModalTemplate')

  self.loadTestimonial = function () {
    return rest('GET', '/api/v2/testimonials/' + self.catering().id)
      .then(function (response) {
        if (response.success) {
          self.testimonial(ko.mapping.fromJS(response.data.testimonial))
        } else {
          pNotify('Problema caricamento Dichiarazione d\'amore', 'error')
        }
        return response
      })
  }

  self.checkSaveTestimonial = function () {
    if (!self.testimonial().is_visible())
      self.saveTestimonial()
    else {
      Swal.fire({
        title: 'Sei sicuro di volerla salvare?',
        text: 'La Tua dichiarazione (d\'amore!) è stata approvata da ' + self.catering().name + '. Salvando la modifica la tua Dichiarazione dovrà essere resa visibile di nuovo dall\'Attività',
        showCancelButton: true,
        cancelButtonColor: '#CC1100',
        confirmButtonText: 'Sì, salva',
        cancelButtonText: 'No, mantieni',
        customClass: {
          confirmButton: 'btn btn-success mg-r-xs',
          cancelButton: 'btn btn-danger',
        },
        buttonsStyling: false
      }).then(function (result) {
        if (result.value) {
          self.saveTestimonial()
        }
      })
    }
  }

  self.saveTestimonial = function () {
    var data = {
      testimonial: self.testimonial(),
    }
    if (self.testimonial().message() && self.testimonial().message().length > 3) {
      rest('PUT', '/api/v2/testimonials/' + self.testimonial().catering_id(), data)
        .then(function (response) {
          if (response.success) {
            self.testimonial(ko.mapping.fromJS(response.data.testimonial))
            pNotify('Dichiarazione (d\'amore!) salvata')
          } else {
            pNotify('Dichiarazione d\'amore non salvata.', 'error')
          }
        })
    } else {
      pNotify('La Dichiarazione d\'amore non può essere vuota o inferiore a 4 caratteri', 'error')
    }
  }

  self.checkDeleteTestimonial = function () {
    if (!self.testimonial().is_visible())
      self.deleteTestimonial()
    else {
      Swal.fire({
        title: 'Sei sicuro di volerla cancellare?',
        text: 'La Tua dichiarazione (d\'amore!) è stata approvata da ' + self.catering().name + '. Cancellandola verrà nascosta dalla Pagina dell\' Attività',
        showCancelButton: true,
        cancelButtonColor: '#CC1100',
        confirmButtonText: 'Sì, elimina',
        cancelButtonText: 'No, mantieni',
        customClass: {
          confirmButton: 'btn btn-success mg-r-xs',
          cancelButton: 'btn btn-danger',
        },
        buttonsStyling: false
      }).then(function (result) {
        if (result.value) {
          self.deleteTestimonial()
        }
      })
    }
  }

  self.deleteTestimonial = function () {
    rest('DELETE', '/api/v2/testimonials/' + self.testimonial().id())
      .then(function (response) {
        if (response.success) {
          pNotify('Dichiarazione (d\'amore!) cancellata')
          self.close()
        } else {
          pNotify('Dichiarazione d\'amore non cancellata.', 'error')
        }
      })
      .catch(function (res) {
        pNotify('Dichiarazione d\'amore non cancellata.', 'error')
      })
  }

  self.load = function (catering) {
    if (catering)
      self.catering(catering)
    self.loadTestimonial().then(function () {
      self.init()

    })
  }

  self.getShareUrl = function () {
    var url = ''
    if (self.catering().bundle.seo)
      url = self.catering().bundle.seo
    else
      url = window.location.origin + '/catering/' + self.catering().id
    if (self.testimonial().user_id())
      url += '?testimonial=' + self.testimonial().user_id() + '%23tab-fan'
    return url
  }

  self.getShareText = function () {
    return 'Ecco perché amo ' + self.catering().name
  }

  self.shareFacebook = function () {

    window.open('https://www.facebook.com/sharer/sharer.php?app_id=836519186411810&sdk=joey&display=popup&ref=plugin&src=share_button&u=' + self.getShareUrl())
  }

  self.shareTwitter = function () {
    window.open('https://twitter.com/share?url=' + self.getShareUrl() + '&text=' + self.getShareText())
  }

  self.shareSms = function () {
    window.open('sms:?body=' + self.getShareText() + ': ' + self.getShareUrl())
  }

  self.shareWhatsapp = function () {
    window.open('https://api.whatsapp.com/send?text=' + self.getShareText() + ': ' + self.getShareUrl())
  }

  return self
}
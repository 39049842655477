function BreadcrumbsModule (homeUrl) {
  var self = this

  self.breadcrumbs = ko.observableArray([])
  self.homeUrl = ko.observable(homeUrl ? homeUrl : '/')

  self.addCrumb = function (url, label, active) {
    self.breadcrumbs.push({ 'url': url, 'label': label, 'active': active })
  }

  self.reset = function () {
    self.breadcrumbs.removeAll()
  }
}

function OfferShareUserModal() {

    var self = new ModalModule();
    self.offer = ko.observable();
    self.headerLabel('Grazie del "Mi Piace"!');
    self.bodyTemplate('offerShareUserModalTemplate');
    self.headerTemplate('headerOfferShareUserModalTemplate');
    self.modalSize('modal-sm');

    self.cleanShareText = function (name, uri, description) {
        return encodeURIComponent(name) + ': ' + encodeURIComponent((description.substr(0, 100)).replace(/<(?:.|\s)*?>/gm, '') + '... ') + encodeURIComponent(uri)
    };


    self.load = function (offer) {
        self.offer(offer);
        self.show();
    };

    return self;
}
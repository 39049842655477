function QuoteModule() {
    var self = this;

    self.add = function(catering) {
        return trovacigusto.flagVM.store('quotes', catering.id);
    };

    self.remove = function(catering) {
        return trovacigusto.flagVM.delete('catering', catering.id);
    }
}
function SearchCateringsModule () {
  var self = this

  self.query = ko.observable('')
  self.errorMessage = ko.observable('')
  self.searched = ko.observable(false)
  self.pagination = new PaginationModule()
  self.filters = ko.observable(null)

  self.labelSearch = ko.computed(function () {
    if (self.filters && self.filters() === 'suppliers')
      return 'Cerca un Fornitore food che ami'
    else
      return 'Cerca una specifica Attività food'
  })

  self.addFavoriteCatering = function (item) {
    if (!trovacigusto.userLogged()) {
      trovacigusto.modalLogin.headerLabel('Già Utente?<br>Accedi per diventare Fan')
      trovacigusto.modalLogin.show()
      return
    }
    Swal.fire({
      title: '<i class="fa fa-heart-o text-contrast" style="margin-top:2px; margin-right:5px;"></i> Vuoi diventare Fan?',
      html: '<p class="text-left">Complimenti, stai per arricchire il tuo food network con questa Attività:</p><br>' +
        '<ul class="text-left">\n' +
        '    <li>Troverai sul tuo Diario le sue Proposte sempre aggiornate e potrai ricevere via email o con notifica le migliori novit&agrave;</li>\n' +
        '    <li>Avrai accesso alle sue esclusive Proposte e Offerte &quot;Solo per Fan&quot;</li>\n' +
        '</ul>\n',
      showCancelButton: true,
      cancelButtonColor: '#CC1100',
      confirmButtonText: 'Sì, conferma!',
      cancelButtonText: 'No, annulla',
      customClass: {
        confirmButton: 'btn btn-success mg-r-xs',
        cancelButton: 'btn btn-gray',
      },
      buttonsStyling: false
    }).then(function (result) {
        if (result.value) {
          trovacigusto.flagVM.store('catering', item.id)
        }
      }
    )
  }

  self.search = function () {
    var query = self.query()
    self.pagination.processor(new CateringProcessorModule())
    self.pagination.reset()
    self.searched(false)
    self.errorMessage('')
    if (self.filters())
      query += '&filters=' + self.filters()

    self.pagination.url('/api/v2/caterings/search?q=' + query)
    self.pagination.load().then(function (response) {
      if (response.success) {
        self.resetSearchQuery()
        self.searched(true)
      } else {
        self.errorMessage(response.message)
      }
    })
  }

  self.showMessage = ko.computed(function () {
    if (self.pagination.data().length <= 0 && self.searched()) {
      return '<p class="text-bold no-mg">La ricerca ha zero risultati.</p><br>' +
        ' <p>L\'Attività che cerchi non c\'è? <br>Permettigli l\'attivazione\n' +
        '                <a class="text-contrast" href="/user/favorite/caterings#locali">\n' +
        '                    grazie al tuo codice invito\n' +
        '                </a> (obbligatorio):\n' +
        '                con la Promo FRIENDS riceverai un credito da 25€!<sup>*</sup><br><br>\n' +
        '                <span class="text-sm">*Il Credito è utilizzabile presso l\'Attività invitata su una spesa minima di 50€ ed entro 90gg\n' +
        '                    dalla data di accettazione del tuo invito.</span>\n' +
        '            </p>'
    }

    if (self.errorMessage !== '') {
      return self.errorMessage()
    }
  })

  self.closeSearch = function () {
    self.pagination.reset()
    self.searched(false)
    self.resetSearchQuery()
  }

  self.resetSearchQuery = function () {
    self.query('')
    self.errorMessage('')
  }

  self.registerInvite = function (item) {
    trovacigusto.modalRegister.headerLabel('Per diventare Fan crea il tuo account gratuito')
    trovacigusto.modalRegister.loginRegisterModule.modeInvite(true)
    trovacigusto.modalRegister.loginRegisterModule.catering(item)
    trovacigusto.modalRegister.loginRegisterModule.invitationCode(item.referral_code)
    trovacigusto.modalRegister.loginRegisterModule.senderInviteType('catering')
    trovacigusto.modalRegister.show()
  }

  return self

}
function FlagProcessorModule(processor) {
    var self = this;

    /**
     *
     * @param data
     * @param target
     * @returns {*}
     */
    self.process = function (data, target) {
        if (Array.isArray(data)) {
            if (!target) {
                target = [];
            }
            ko.utils.arrayForEach(data, function (item) {
                target.push(self.addInformation(item));
            });
            return target;
        }
        return self.addInformation(data);
    };


    /**
     * add other information to object
     * @param item
     * @returns {*}
     */
    self.addInformation = function (item) {
        item.user = item.user && processor ? processor.process(item.user) : null;

        item.showTestimonial = ko.observable(false);
        item.enabled = ko.observable(item.enabled);
        item.enabled_at = ko.observable(item.enabled_at);
        item.blocked = ko.observable(item.blocked);
        item.blocked_at = ko.observable(item.blocked_at);

        item.toggleTestimonial = function () {
            if (item.showTestimonial())
                item.showTestimonial(false);
            else
                item.showTestimonial(true);

        }

        return item;

    }

}


function ModalModule () {
  var self = this
  self.bodyContent = ko.observable()
  self.bodyTemplate = ko.observable()

  self.footerTemplate = ko.observable()
  self.headerLabel = ko.observable()
  self.data = ko.observable()
  self.headerTemplate = ko.observable()
  self.modalSize = ko.observable('modal-md')
  self.modalVisible = ko.observable(false)

  //@TODO RIMUOVERE
  self.init = function (template, data) {

    if (data !== null && data) {
      if (data)
        self.data(data)
      if (data.bodyContent)
        self.bodyContent(data.bodyContent)
      if (data.user)
        self.user(data.user)
      if (data.catering) {
        self.catering(data.catering)
        if (self.catering().testimonial)
          self.testimonialMessage(self.catering().testimonial())
      }
      if (self.bodyTemplate() !== template)
        self.bodyTemplate(template)
      if (data.headerLabel) {
        self.headerLabel(data.headerLabel)
      } else {
        self.bodyTemplate.notifySubscribers()
      }
    }

    self.show()
  }

  self.reset = function () {

  }

  self.show = function () {
    closeAllModal()
    self.modalVisible(true)
  }

  self.close = function () {
    self.modalVisible(false)
  }

  self.toggle = function () {
    self.modalVisible() ? self.modalVisible(false) : self.modalVisible(true)
  }

  self.switchTemplates = function (template) {
    if (template)
      closeModalById(template)
    self.show()
  }

  self.fbSend = function (url) {
    if (isGonative())
      gonative.share.sharePage();
    if (isMobile()) {
      window.open('https://www.facebook.com/sharer/sharer.php?app_id=836519186411810&sdk=joey&display=popup&ref=plugin&src=share_button&u=' + url)
    } else {

      FB.ui({
        method: 'send',
        link: url
      })
    }
  }

}

function closeModalById (id) {
  $('#' + id).modal('hide')
  $('html').css('overflow', 'none')

}

function closeAllModal () {
  $('.modal').modal('hide')

}